import { useMigration } from '#migration/migration'
import { useImageUploader } from '#migration/uploader'
import { useRavRecordMocks } from '#record/mockery'
import { RavRecord } from '#record/model'
import { Asset } from '#submission/model'
import { utils } from 'apprise-frontend-core/utils/common'
import { Button } from 'apprise-ui/button/button'
import { FileBox } from 'apprise-ui/filebox/filebox'
import { Form } from 'apprise-ui/form/form'
import { SliderBox } from 'apprise-ui/sliderbox/slider'
import { SwitchBox } from 'apprise-ui/switchbox/switchbox'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'
import { saveAs } from "file-saver"
import { useState } from 'react'

export type ImageMap = Record<string, {
    file: File
    height?: number
    width?: number,
    path: string
    stream: Asset | undefined
    uvis: Record<string, true>

}>

export type UploadRecord = {
    path: string
    stream: Asset
    uploaded: boolean
}


export const DevSettingsPanel = () => {

    const [recordMocks, recordMocksSet] = useState<number>(10000)

    const mocks = useRavRecordMocks()
    const task = useAsyncTask()

    const parties = 30
    const history = Math.max(1, recordMocks / 10000 * 1.6)  // history grows every 2 yrs on average, or 1.6 every 10K record.

    const save = (records: RavRecord[], title: string) => saveAs(new Blob([records.map(r => JSON.stringify(r)).join("\n")], { type: 'text/csv' }), title)

    const mapImagesToUploadArray = (images: ImageMap) => Object.keys(images).filter(k => images[k].stream).reduce((acc, cur) => [...acc, { path: cur, stream: images[cur].stream!, uploaded: false }], [] as UploadRecord[])

    const saveUploadRecords = (records: UploadRecord[], title: string) => saveAs(new Blob([JSON.stringify(records)], { type: 'application/json' }), title)

    const generate = task.make(async () => Promise.resolve().then(utils().wait(200)).then(() => mocks.generate({
        tenantsMin: parties,
        tenantsMax: parties,
        recordMin: recordMocks / parties / (history / 2),
        recordMax: recordMocks / parties / (history / 2),
        historyMin: 1,
        historyMax: history
    })).then(records => save(records, `${new Date().toISOString()} mocks (${records.length}).csv`)))
        .with(cfg => cfg.notify().show(`Generating ${recordMocks} mock records...`)).done()



    const generateBtn = <Button size='small' type='primary' onClick={generate}>Generate Records</Button>

    const [migrationFile, migrationFileSet] = useState<File | undefined>()
    const [uploadRecordFile, uploadRecordFileSet] = useState<File | undefined>()

    const [migrationSize, migrationsSizeSet] = useState<number | undefined>()
    const [resolveImageToggle, resolveImageToggleSet] = useState<boolean>(true)


    const migration = useMigration(migrationSize)
    const uploader = useImageUploader()

    const acceptedImages = [
        'image/jpeg', 'image/jpg', 'image/webp', 'image/png', 'image/bmp'
    ]

    const re = /(.*) R$/


    const scanImages = task.make(async ( props: { sizeImages: boolean}) => {

        var dirs: Record<string, boolean> = {}
        
        let imagemap = {} as ImageMap
        let sizers = [] as Promise<any>[]


        const {sizeImages} = props

        const fileHandlerSorter = (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        
            
        const scanRecursive = async (dirHandle) => {

            for await (let [, handle] of dirHandle) {
                const { kind, name } = handle
                if (kind === 'directory' && name.match(re))
                    dirs[name] = true

            }

            let fileHandlers = [] as any[]

            for await (let [, handle] of dirHandle) {
                fileHandlers.push(handle)
            }

            fileHandlers.sort(fileHandlerSorter)

            for (const handle of fileHandlers) {
                
                const { kind, name } = handle

                if (kind === 'directory') {
                    if (!dirs[`${name} R`]) {
                            await scanRecursive(handle)
                    }

                }
                else {

                    const file = await handle.getFile() as File

                    if (acceptedImages.includes(file.type)) {

                        if (!sizeImages) {

                            imagemap[file.name.toLowerCase()] = { file, stream: undefined } as ImageMap[string]
                        }

                        const img = new Image();

                        const src = URL.createObjectURL(file)

                        const sizer = new Promise((res, rej) => {

                            img.onload = () => {

                                URL.revokeObjectURL(src)

                                //console.log('adding',file.name)

                                imagemap[file.name.toLowerCase()] = { file, width: img.naturalWidth, height: img.naturalHeight, path: undefined!, uvis: {}, stream: undefined } 
                                
                                res(undefined)

                            }

                            img.onerror = res

                        })

                        sizers.push(sizer)

                        img.src = src;

                    }
                }

            }

          
        }

        const dirHandler = await (window as any).showDirectoryPicker()

        await scanRecursive(dirHandler)

        await Promise.allSettled(sizers)

        console.log("finished indexing images",{imagemap, size: Object.keys(imagemap).length})

        return imagemap
    })
        .with($ => $.show("indexing images..."))
        .done()

    const migrate = async () => {

        const images = resolveImageToggle ? await scanImages({sizeImages:true}) : {} as ImageMap

        const outcome = await migration({ file: migrationFile!, images })

        console.log({ rows: outcome.data?.length, images: outcome.images, unresolvedImages: outcome.imageUnresolved })

        const crossrefs = Object.entries(images).filter(([, rec]) => Object.keys(rec.uvis).length > 1)

        crossrefs.forEach(([path, rec]) => outcome.issues.push(
            { type: 'error', message: `same image ${path} referenced by multiple vessels [${Object.keys(rec.uvis).join(",")}]` }
        ))

        if (crossrefs.length)
            console.log("cross-ref vessels", utils().dedup(crossrefs.flatMap(e => Object.keys(e[1].uvis))))

        if (outcome.issues.length)
            console.error(outcome)


        save(outcome.data, `${new Date().toISOString()} migration (${outcome.data.length}).csv`)

        const uploadRecords = mapImagesToUploadArray(images)

        if (uploadRecords.length > 0)
            saveUploadRecords(uploadRecords, `${new Date().toISOString()} image upload records (${uploadRecords.length}).json`)

    }

    const upload = async () => {

        const images = await scanImages({sizeImages:false})

        const { outcome, stats } = await uploader(uploadRecordFile!, images)

        if (outcome.issues.length || stats.errors) {
            console.error(outcome, stats.errors)
        }

        saveUploadRecords(outcome.data, `${new Date().toISOString()} image upload records (${outcome.data.filter(rec => rec.uploaded).length} out of ${outcome.data.length}).json`)
    }

    return <Form>

        <SliderBox label='Generate Mocks' msg={generateBtn} max={100000} step={1000} onChange={num => recordMocksSet(num ?? 0)}>
            {recordMocks}
        </SliderBox>


        <br />
        <br />


        <FileBox<File> maxSizeMb={50} multi={false} label='Generate From Legacy' msg='Upload a dump of the legacy db.' descriptorOf={f => f} onChange={files => migrationFileSet(files?.[0])}>
            {migrationFile}
        </FileBox>

        <SliderBox enabled={!!migrationFile} max={100000} marks={{ 100000: 'All' }} step={10} onChange={num => migrationsSizeSet(num ?? 0)}>
            {migrationSize}
        </SliderBox>

        <SwitchBox enabled={!!migrationFile} label="Resolve images" onChange={v => resolveImageToggleSet(!!v)}>{resolveImageToggle}</SwitchBox>

        <Button enabled={!!migrationFile} size='small' type='primary' onClick={migrate}>Migrate Records</Button>

        <br /><br />

        <FileBox<File> multi={false} label='Upload resolved images' descriptorOf={f => f} onChange={files => uploadRecordFileSet(files?.[0])}>
            {uploadRecordFile}
        </FileBox>

        <Button enabled={!!uploadRecordFile} size='small' type='primary' onClick={upload}>Upload Images</Button>


    </Form>

}