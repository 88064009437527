import { Dropdown, Menu as AntMenu } from 'antd'
import { utils } from 'apprise-frontend-core/utils/common'
import { Button, ButtonProps } from 'apprise-ui/button/button'
import { Component, useDeferredComponentProps } from 'apprise-ui/component/component'
import { classname, Clicked, Sized, Styled, Tall } from 'apprise-ui/component/model'
import { Label, LabelProps } from 'apprise-ui/label/label'
import * as React from 'react'
import { DownIcon, MenuIcon } from '../utils/icons'
import './styles.scss'

export type MenuProps = Styled & Sized & Tall & Partial<{

  children: JSX.Element | (JSX.Element | false)[] | false

  open: boolean | undefined
  onChange: (_: boolean) => void

  noAutoclose: boolean

  noIcon: boolean

  type: ButtonProps['type']
  label: React.ReactNode

  placement: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight'


}>


export const Menu = (props: MenuProps) => {

  const [uncontrolledOpen, uncontrolledOpenSet] = React.useState(false)

  const { label = <MenuIcon />, noIcon, height, type = 'ghost', placement = 'bottomRight', size } = props
  const { children: items = [], open = uncontrolledOpen, onChange = uncontrolledOpenSet, noAutoclose } = props

  const { getComponentProps } = useDeferredComponentProps()

  const allItems = utils().arrayOf(items)

  const entries = !items ? <></> : <AntMenu onClick={() => noAutoclose || onChange(false)} items={

    utils().elementsIn(allItems).map((item: JSX.Element, i) => {


      const { disabled } = getComponentProps(item.props ?? {})

      const itemDisabled = disabled

      if (utils().isElementOf(Menu.Item)(item)) {

        const { onClick } = item.props as MenuItemProps

        return { key: i, className: 'menu-item', disabled, onClick: () => onClick?.(), label: <Label {...item.props} disabled={itemDisabled} noTip noDecorations /> }
      }

      if (utils().isElementOf(Menu.Divider)(item))
        return { type: 'divider', key: i }

      const { className, style } = item.props ?? {}

      return { key: i, className, style, disabled, label: item }

    })

  } />


  return <Component name='menu' {...props} >

    <Dropdown trigger={['click']} placement={placement} overlayStyle={{ height }} overlayClassName={classname('apprise-menu-items', props.className)} open={open} onOpenChange={onChange} overlay={entries} >

      <div>
        <Button icon={noIcon ? undefined : <DownIcon size='10' />} noReadonly size={size} className='menu-placeholder' type={type} onClick={() => onChange(!open)}>{label}</Button>
      </div>

    </Dropdown>

  </Component >

}

export type MenuItemProps = Partial<React.PropsWithChildren<LabelProps & Clicked>>

Menu.Item = function Item(_: MenuItemProps) { return null }
Menu.Divider = function Divider() { return null }