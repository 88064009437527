import { useT } from 'apprise-frontend-core/intl/language'
import { LazyBusyGuard, useBusyFlag } from 'apprise-frontend-core/utils/busyguard'
import { UserIcon } from 'apprise-frontend-iam/user/constants'
import { User } from 'apprise-frontend-iam/user/model'
import { useUserStore } from 'apprise-frontend-iam/user/store'
import { classname } from 'apprise-ui/component/model'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { useEffect, useState } from 'react'
import { IoMdRefresh } from 'react-icons/io'
import { useAccounts } from './accounts'
import { accountColors } from './constants'
import './label.scss'
import { Account } from './model'

export const AccountLabel = (props: LabelProps & {

    user: User
    account: Account

    displayMode?: 'default' | 'short'

}) => {

   
    return <LazyBusyGuard>
            <InnerLabel {...props} />
            </LazyBusyGuard>
}

export const InnerLabel = (props: LabelProps & {

    user: User
    account: Account

    displayMode?: 'default' | 'short'

}) => {

    const t = useT()

    const accounts = useAccounts()
    const store = useUserStore()

    const busy = useBusyFlag()

    const { user, account, displayMode = 'default', decorations } = props

    const initialState = accounts.extendedStateOf(user, account)

    const [state, setState] = useState(initialState)

    useEffect(() => {

        if (initialState !== state)
            setState(initialState)


    }, [state, initialState])

    const title = t(`account.state_${state}_label${displayMode === 'short' ? '_short' : ''}`)

    const refresh = async () => {

        const account = await accounts.customFetchAccount(config => config.minimumDuration(500))(user)

        setState(accounts.extendedStateOf(user, account))

        store.updateAccountData(user, account)


    }

    const refreshBtn =  <IoMdRefresh  className={classname(`refresh-icon`, busy && 'refresh-busy')} onClick={refresh} />

    const augmentedDecorations = ['unknown', 'pending'].includes(state) ? [refreshBtn, ...decorations ?? []] : decorations

    return <Label className='account-label' fill={accountColors[state]} icon={<UserIcon />} title={title} tip={t(`account.state_${state}_tip`)} mode='tag' decorations={augmentedDecorations} {...props} />

}