import { TenantLabel } from 'apprise-frontend-iam/tenant/label'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { Tip } from 'apprise-ui/tooltip/tip'
import format from 'date-fns/format'
import { useRecordPluginDescriptors } from '../record/plugin'
import { SubmissionDateIcon } from './constants'
import { Submission, useSubmissionModel } from './model'
import { useSubmissionRouting } from './routing'
import { useSubmissionStore } from './store'


export type SubmissionLabelProps = LabelProps & {

    submission: string | Submission
    displayMode?: 'tenant' | 'start' | 'end' | 'type' | 'state' | 'stateaccented'
    stateDecoration?: boolean
    dateMode?: 'short' | 'long'

}

export const SubmissionLabel = (props: SubmissionLabelProps) => {

    const { submission, dateMode = 'short', stateDecoration, displayMode = 'start', decorations = [], ...rest } = props

    const model = useSubmissionModel()
    const routing = useSubmissionRouting()
    const store = useSubmissionStore()

    const resolved = typeof submission === 'string' ? store.lookup(submission)! : submission // todo: replace with safelookup

    const { Icon, name } = useRecordPluginDescriptors().lookup(resolved.type)

    const StateIcon = model.statusIconOf(resolved)
    
    const route = routing.detailRoute(resolved.id)

    const stateColor = model.colorOf(resolved)
    const stateName = model.stateOf(resolved)

    if (displayMode === 'state' || displayMode === 'stateaccented')
        return <Label fill={displayMode === 'state' ? undefined : stateColor} icon={<StateIcon color={stateColor} />} title={stateName} linkTo={route} {...rest} />

    // in all other modes, state is a decoration.
    if (stateDecoration)
        decorations?.push(

            <Tip tip={stateName}>
                <StateIcon />
            </Tip>

        )

    if (displayMode === 'tenant')
        return <TenantLabel tenant={resolved.tenant} linkTo={route} decorations={decorations} {...rest} />


    if (displayMode === 'type')
        return <Label noReadonly icon={<Icon />} title={name} />


    const date = displayMode === 'start' ? resolved.lifecycle.created ?? new Date() : resolved.lifecycle.published

    return <Label icon={<SubmissionDateIcon />}
        linkTo={route} title={date ? format(date, dateMode === 'short' ? 'PP' : 'PPp') : undefined}
        decorations={decorations}
        {...rest} />

}