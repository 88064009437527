import { useT } from 'apprise-frontend-core/intl/language'
import { TagLabel } from 'apprise-frontend-tags/tag/label'
import { TagReference } from 'apprise-frontend-tags/tag/model'
import { classname } from 'apprise-ui/component/model'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { VidIcon } from './constants'
import './label.scss'
import { useVID, Vid } from './model'

export type VidLabelProps = LabelProps & Partial<{

    displayMode: 'scheme' | 'value'

    vid: Vid
    scheme?: TagReference

}>

export const VidLabel = (props: VidLabelProps) => {

    const t = useT()
    const vids = useVID()

    const { vid, scheme, icon, displayMode = 'value', noIcon, ...rest } = props


    let value = vids.valueOf(vid)

    const specialValue = value === t('vid.pending_value)') || value === t('vid.ne_value') 

    if ( value === t('vid.pending_value)') ) 
        value = t('vid.pending_label')

    if ( value === t('vid.ne_value') ) 
        value = t('vid.ne_label')

    if ( value === t('vid.na_value') ) 
        value = t('vid.na_label')

    const classes = classname('apprise-vidlabel', specialValue && 'vidlabel-special')

    return scheme || displayMode === 'scheme' ?

        <TagLabel icon={icon ?? <VidIcon />} tag={scheme} {...rest} />
        
        :

        <Label className={classes} mode={ specialValue ? 'tag' : 'normal'} icon={icon ?? <VidIcon />} title={vids.valueOf(vid) ?? ''} tip={vids.schemeOf(vid)?.description} noIcon={!specialValue && noIcon}  {...rest} />

}