import { Tabs } from 'antd'
import { utils } from 'apprise-frontend-core/utils/common'
import { Component, useComponentProps, useDeferredComponentProps } from 'apprise-ui/component/component'
import { Disabled } from 'apprise-ui/component/model'
import { Label } from 'apprise-ui/label/label'
import { Readonly } from 'apprise-ui/readonly/model'
import { Tipped } from 'apprise-ui/tooltip/model'
import { paramsIn, updateQuery, useRouting } from 'apprise-ui/utils/routing'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { DotIcon } from '../utils/icons'
import "./styles.scss"



type TabOptions = {

    param: string
}

type UseResult = {


    tabs: JSX.Element,
    selectedTabContent: any,
    setSelectedTab: (_: string) => void,
    selectedTab: string
}


export const useTabs = (tabs: (JSX.Element | false | undefined)[], options: Partial<TabOptions> = {}): UseResult => {

    const { param = 'tab' } = options

    const history = useHistory()
    const { pathname, search } = useLocation()
    const routing = useRouting()

    const routeTo = (tab: string) => history.push(`${pathname}${updateQuery(search).with(params => params[param] = tab)}`)

    const componentProps = useDeferredComponentProps()

    // complements a set of props with defaults for the relevant ones.
    const adaptProps = (position: number, props: any) => {

        const { id, label, icon, defaultTab, children = <div>tab {position + 1}</div>, ...rest } = componentProps.getComponentProps(props)

        return {
            id: id ?? `${position + 1}`,
            label: label ?? `tab ${position + 1}`,
            icon: icon ?? <DotIcon />,
            defaultTab,
            children,

            ...rest

        }
    }

    // adapts children props and transforms children into <Tab>s if they are not already.
    const childprops = utils().elementsIn(tabs).map((e, i) => adaptProps(i, e.props))

    const defaultTab = childprops.find(p => p.defaultTab) ?? childprops?.[0]

    const activeParam = paramsIn(search)[param] 

    const activeId = activeParam ?? defaultTab?.id

    const activeTab = childprops.find(p => p.id === activeId) ?? defaultTab

    const activeIsDisabled =  activeTab?.disabled

    React.useEffect(()=>{

        if (activeIsDisabled)
            history.push(routing.routeQueryTo( query => query[param]=undefined))

     //eslint-disable-next-line      
    },[activeIsDisabled, param])

    // enables animation after mount, to avoid stutter. 
    // with a delay as the anmation itself is delayed, and if we run first we will have it always enabled.
    const ref = React.useRef(false)

    React.useEffect(() => {

        const ts = setTimeout(() => ref.current = true, 100)
        return () => clearTimeout(ts)

    }, [])

    const tabselement = <Component name="tabs" >

        <Tabs animated={ref.current} activeKey={activeId} onChange={routeTo} items={childprops.map(props => {

                const tab = <Tab {...props}  />

                return {label:tab, key:props.id, disabled: props.disabled }
 
            })}

        />
    </Component>


    return {
        tabs: tabselement,
        selectedTabContent: activeTab?.disabled ? null : activeTab?.children,
        setSelectedTab: routeTo,
        selectedTab: activeTab?.id
    }
}

type TabProps = Tipped & Disabled & Readonly & {

    id: string
    defaultTab: boolean
    icon: JSX.Element
    label: React.ReactNode

    children: React.ReactNode

}

export const Tab = ($: Partial<TabProps>) => {

    const { icon, label, ...rest } = $

    const { disabled } = useComponentProps($)

    return <Component name="tab" {...rest} disabled={disabled}>
        <Label  icon={icon} title={$.label} {...rest} />
    </Component>
}

