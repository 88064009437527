import { Layout } from 'antd'
import { LazyBusyGuard, useBusyGuardIndicator } from 'apprise-frontend-core/utils/busyguard'
import { utils } from 'apprise-frontend-core/utils/common'
import { Component } from 'apprise-ui/component/component'
import { StyledContainer, Tall, classname } from 'apprise-ui/component/model'
import { ReadOnly } from 'apprise-ui/readonly/readonly'
import { Titlebar } from 'apprise-ui/titlebar/titlebar'
import { Topbar } from 'apprise-ui/topbar/topbar'
import { IconReadonly } from 'apprise-ui/utils/icons'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { Header } from '../header/header'
import { PageModel } from './model'
import { Scroller } from './scroller'
import { Sidebar, SidebarContent } from './sidebar'
import "./styles.scss"



const defaultPageModel: Partial<PageModel> = {
    sections: []
}
export const PageModelContext = React.createContext<Partial<PageModel>>(defaultPageModel)

export type PageProps = StyledContainer & Tall & {

    children: React.ReactNode

    noHeader: boolean

    sidebar: true                            // shared element includes.
    noSidebar: true

    defaultOpen: boolean

    fullBleed: boolean

    readonly: boolean

    boxed: boolean

    noIndicator: boolean

}

export const Page = (props: Partial<PageProps>) => {

    const { hash } = useLocation()

    const model = React.useContext(PageModelContext)

    const { sidebar = model?.sidebar, readonly = false, children = [], defaultOpen, fullBleed, noHeader, noSidebar, noIndicator, boxed, innerClassName, innerStyle, height = boxed ? '100%' : '100vh' } = props

    const $children = Array.isArray(children) ? children : [children]

    const titlebar = $children.find(utils().isElementOf(Titlebar))
    const topbar = $children.find(utils().isElementOf(Topbar))
    const headerChild = $children.find(utils().isElementOf(Header))
    const sidebarContent = $children.find(utils().isElementOf(SidebarContent))

    const header = noHeader ? undefined : headerChild ?? model.header
    const withSidebar = (sidebar || sidebarContent) && !noSidebar

    const other = $children.filter(c => c !== titlebar && c !== topbar && c !== headerChild && c !== sidebarContent)

    const latestHash = React.useRef<string>(undefined!)

    React.useEffect(() => {

        if (hash && hash !== latestHash.current) {

            latestHash.current = hash;

            const el = document.getElementById(latestHash.current?.slice(1))

            if (el)
                el.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }

    })


    const contentClasses = classname('page-main-content', fullBleed && 'page-content-bleed', innerClassName)

    return < LazyBusyGuard dispatch={false}>
        <ReadOnly value={readonly}>

            <Component style={{ height }} name='page' {...props}>

                {/* NOTE: this class accounts for sidebar and switches the layout from flex column to flex row.
                    the layout acquires this class when it contains a sidebar and the work is likely done by
                    the sidebar itself, probably via a ref. initially, however, the sidebar is included in the column layout
                    and is included in dhynamic calculation such as those performed by a table's autosizer.
                    the autosizer would then correct its initial calculation, causing a double render on mount. this is costly
                    for heaveyweight renders such as the table's. we know the sidebar exists early on, so we can set it from the
                    beginning, stabilizing the calculations of the autosizer.
                 */}
                <Layout className={withSidebar ? 'ant-layout-has-sider' : ''}>

                    {withSidebar &&

                        <Sidebar defaultOpen={defaultOpen} {...sidebarContent?.props}>{sidebarContent}</Sidebar>

                    }

                    <Layout className="page-main">

                        {header &&

                            <Layout.Header className='page-header focus-mode-aware-top'>

                                <Header>

                                    <Header.Left>
                                        <IconReadonly />
                                    </Header.Left>

                                    {header}

                                </Header>

                            </Layout.Header>
                        }

                        {<Layout.Content>

                            <Scroller offsetTop={100} className='page-scroller'>

                                {titlebar}
                                {topbar}

                                <div style={innerStyle} className={contentClasses}>
                                    {noIndicator ?

                                        other

                                        :

                                        <PageBody>
                                            {other}
                                        </PageBody>

                                    }
                                </div>

                            </Scroller>


                        </Layout.Content>}

                    </Layout>

                </Layout>

            </Component>

        </ReadOnly >
    </LazyBusyGuard>
}

const PageBody = (props: React.PropsWithChildren<{}>) => {

    const Indicator = useBusyGuardIndicator()

    const { children } = props

    return <Indicator>
        {children}
    </Indicator>

}