
import { Checkbox } from 'antd'
import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { Component, useDeferredComponentProps } from 'apprise-ui/component/component'
import { classname, Debugged } from 'apprise-ui/component/model'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { Linked } from 'apprise-ui/link/model'
import * as React from 'react'
import { AiOutlineCheckSquare } from 'react-icons/ai'
import { Menu, MenuProps } from '../menu/menu'
import "./styles.scss"


/**   
 
  returns a "component kit" for <OptionMenu>, ie. an object that includes:

  - 'active': an array of currently active options.
  - 'setActive': a function to change the active options programmatically.
  - 'OptionMenu': a proxy of <OptionMenu> preset with 'active' and 'setActive'.
  - 'Option': an alias for <OptionMenu.Option> that accepts only correctly typed options.

*/

export type UncontrolledMenuProps<T extends any = any> = Partial<{

    initiallyActive: T[],
    initiallyOpen?: boolean
}>

export type OptionMenuKit<T extends any = any> = {

    OptionMenu: (_: OptionMenuProps<T>) => JSX.Element;
    Option: (_: OptionProps<T>) => null
    Divider: () => null
    active: T[],
    setActive: (_: T[]) => any
    open: boolean,
    setOpen: (value: boolean) => void;
}

export const useOptionMenu = <T extends any = any>(props: UncontrolledMenuProps<T>): OptionMenuKit<T> => {

    const { initiallyActive = [], initiallyOpen } = props

    // state is in refs so we can memoise the proxy _and: access the latest data.
    // because we use refs, we trigger renders manually when the ref value changes. 
    const active = React.useRef(initiallyActive)
    const open = React.useRef(initiallyOpen)

    const [, render] = React.useState(0)

    // updates options and trigger a render
    const activeSet = options => {

        active.current = options
        render(i => ++i)
    }

    const openSet = (value: boolean) => {

        open.current = value
        render(i => ++i)
    }

    // if we don't memoize, React sees elements of different components and remounts them at each render.
    const ProxyMenu = React.useCallback(

        (props: OptionMenuProps<T>) => <OptionMenu active={active.current} setActive={activeSet} open={open.current} onChange={openSet} {...props} />

        , [])


    return {

        OptionMenu: ProxyMenu,
        Option: OptionMenu.Option,
        Divider: Menu.Divider,
        active: active.current,
        setActive: activeSet,
        open: !!open.current,
        setOpen: openSet,
    }

}

export type idFun<T extends any = any> = (_: T) => string | number


export type OptionMenuProps<T> = Debugged & MenuProps & Partial<{

    id: idFun<T>

    active: T[]
    setActive: (_: T[]) => any

    noHighlight: boolean
    highlightOn: 'active' | 'inactive'
    noSelectAll: boolean

}>

export const OptionMenu = <T extends any = any>(props: OptionMenuProps<T>) => {

    const t = useT()

    const defaultLabel = <Label noReadonly icon={<AiOutlineCheckSquare />} title={t("ui.menu.option_default_title")} />

    const { id = t => t, active = [], setActive, label = defaultLabel, noHighlight, noSelectAll, children, highlightOn = 'inactive' } = props

    const options: T[] = []
    const enabledOptions: T[] = []
    const checkedOptions: T[] = []

    const { getComponentProps } = useDeferredComponentProps()

    const items = utils().elementsIn(children).map((item, i) => {

        if (utils().isElementOf(OptionMenu.Option)(item)) {

            const props = item.props as OptionProps<T>

            const { value, disabled, noReadonly } = getComponentProps(props)

            options.push(value)

            if (!disabled)
                enabledOptions.push(value)

            const valueId = id(value)

            const index = active.findIndex(o => id(o) === valueId)

            const checked = index >= 0 && !disabled

            if (checked)
                checkedOptions.push(value)

            const onClick = () => setActive?.(index >= 0 ? active.filter((_, j) => j !== index) : [...active, value])

            return <Menu.Item key={i} noIcon {...item.props} title={

                <Checkbox className='apprise-row' disabled={disabled && !noReadonly} checked={checked} onClick={onClick}>
                    {item.props.title}
                </Checkbox>

            } />

        }

        return item

    })
    


    const previousOptions = React.useRef(enabledOptions)

    React.useEffect(() => {

        // new options?

        const newOptions = enabledOptions.filter(o => previousOptions.current.findIndex(oo => id(o) === id(oo)) < 0)

        const added = utils().dedup([...active, ...newOptions])

        if (newOptions.length > 0)
            setActive?.(added)

        previousOptions.current = enabledOptions

        // removed options?

        const removedOptionIds = active.filter(o => enabledOptions.findIndex(oo => id(o) === id(oo)) < 0).map(o => id(o))

        if (removedOptionIds.length > 0)
            setActive?.(added.filter(o => !removedOptionIds.includes(id(o))))


    })

    const includeSelectAll = enabledOptions.length > 0 && !noSelectAll

    if (includeSelectAll) {

        const allChecked = enabledOptions.length === checkedOptions.length
        const onClick = () => setActive?.(allChecked ? [] : enabledOptions)

        items.unshift(<Menu.Divider key='select-all-divider' />)
        items.unshift(
            <Menu.Item key='select-all' noIcon title={<Checkbox checked={allChecked} onClick={onClick} >{t("ui.menu.select_all")}</Checkbox>} />
        )

    }

    const highlight = !noHighlight && (highlightOn === 'active' ? active.length > 0 : active.length !== enabledOptions.length) 


    props.debug && console.log({previousOptions, enabledOptions, active, highlight, items, options})

    
    return <Component name='option-menu' className={classname(!highlight && 'menu-no-highlight')} {...props} >
        <Menu type={highlight ? 'primary' : 'normal'} {...props} label={label} noAutoclose>
            {items}
        </Menu>
    </Component>
}

// like a menu item, without links
export type OptionProps<T> = Partial<Omit<LabelProps, keyof Linked>> & {

    value: T
}


OptionMenu.Option = function Option<T = any>(_: OptionProps<T>) { return null }