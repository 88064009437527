
import { fishingKindTag, loaTag } from '#details/constants'
import { ResultBatch, SearchDto } from '#search/model'
import { Vid } from '#vid/model'
import { useCall } from 'apprise-frontend-core/client/call'
import { usePreload } from 'apprise-frontend-core/client/preload'
import { useLanguage, useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { TenantReference } from 'apprise-frontend-iam/tenant/model'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'


export const recordApi = "/record"

export const searchApi = `${recordApi}/search`




export const useRecordCalls = () => {

    const t = useT()

    const call = useCall()

    const lang = useLanguage()

    const task = useAsyncTask()

    const preload = usePreload()

    const search = (dto: SearchDto, noPreload? : boolean) => {
    
        const request = () => call.at(searchApi).post<ResultBatch>(dto)

        return noPreload ? request() : preload.get<ResultBatch>(searchApi) ?? request()
    
    }

    const self = {

        search

        ,

        fetchForSubmission: task.make(async (uvis: Vid[]) => {

            const dto: SearchDto = {

                language: lang.current(),
                mode: 'current', 
                conditions: [{ ids: uvis }],
                includeDelisted: true,
                sort: [],
                cursor: { pageSize: uvis.length }
            }

            const { results } = await search(dto, true)

            return utils().index(results).by(v => v.uvi)

        })
            .with($ => $.show(t('rec.fetch_many')).throw({
                title: t('rec.fetch_many_error_title'),
                message: t('rec.fetch_many_error_msg')

            }))
            .done()

        ,

        canTranship: task.make(async (tenant: TenantReference) => {

            const dto: SearchDto = {
                language: lang.current(),
                mode: 'current', 
                sort: [],
                conditions: [ { spec: loaTag, min: 24}, { kinds: [fishingKindTag]},{ flags:[tenant]}],
                includeDelisted: false,
                cursor: { page: 1, pageSize: 0}
            }

            const { total } = await search(dto, true)

            return total > 0

        })
            .with($ => $.show(t('rec.fetch_many')).throw({
                title: t('rec.fetch_many_error_title'),
                message: t('rec.fetch_many_error_msg')

            }))
            .done()

    }

    return self
}