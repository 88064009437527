
import { useMode } from 'apprise-frontend-core/config/api'
import { useT } from 'apprise-frontend-core/intl/language'
import { Tenant } from 'apprise-frontend-iam/tenant/model'
import { useMultiTagFilter } from 'apprise-frontend-tags/tag/filter'
import { Button } from 'apprise-ui/button/button'
import { Page } from 'apprise-ui/page/page'
import { SidebarContent } from 'apprise-ui/page/sidebar'
import { Selection, useSelection } from 'apprise-ui/table/selection'
import { SortSpec } from 'apprise-ui/table/sorting'
import { Table } from 'apprise-ui/table/table'
import { Titlebar } from 'apprise-ui/titlebar/titlebar'
import { Topbar } from 'apprise-ui/topbar/topbar'
import { useBaseFilter } from 'apprise-ui/utils/basefilter'
import { AddIcon, DevOnlyIcon } from 'apprise-ui/utils/icons'
import { useMemo } from 'react'
import { useInternalUserOracle } from '../authz/oracle'
import { useUserColumns } from './columns'
import { userType } from './constants'
import { User } from './model'
import { useUserRouting } from './routing'
import { useUserStore } from './store'
import { useUserEmailCopyToClipboard } from './utils'
import { useUserToggles } from './toggles'
import { useUserDownload } from './download'

export const userTableName = 'user-table'

export const UserList = () => {

    const t = useT()
    const store = useUserStore()
    const routing = useUserRouting()
    const { development } = useMode()
    const userOracle = useInternalUserOracle()
    const selection = useSelection<User>()

    const toggles = useUserToggles()

    const copyAddressesBtn = useUserEmailCopyToClipboard()

    const {UserDownloadDrawer, userDownloadBtn} = useUserDownload()

    // actions

    const removeMany = () => store.removeMany(selection.selected, () => selection.select([]))

    const add = () => routing.routeToNewDetail()

    // buttons

    const addBtn = < Button type="primary" icon={<AddIcon />} onClick={add} enabled={userOracle.canAdd()} linkTo={routing.newDetailRoute()}>
        {t("user.add", { singular: t('user.singular') })}
    </ Button>

    const removeManyBtn = <Button iconPlacement='left' type="danger" icon={<DevOnlyIcon size={22} color='orange' />} onClick={removeMany} >
        {t("ui.feedback.consent_removal_many_title", { count: selection.selected.length })}
    </Button>


    return <Page >

        <SidebarContent>
            {addBtn}
            <br />
            {development && selection.selected.length > 0 && removeManyBtn}
            <br/>
            {copyAddressesBtn}
            {toggles.download && userDownloadBtn}
        </SidebarContent>

        <Titlebar title={t('user.list_title')} />

        <Topbar>
            {addBtn}
        </Topbar>

        <UserTable selection={selection} />

        <UserDownloadDrawer />

    </Page>
}


export type UserTableProps = {

    tenant?: Tenant
    selection: Selection<User>

}

export const UserTable = (props: UserTableProps) => {

    const store = useUserStore()
    const userOracle = useInternalUserOracle()

    const userCols = useUserColumns(props)

    // filters

    const { tenant, selection } = props

    const allUsers = store.all()

    const unfilteredData = useMemo(() => tenant ? allUsers.filter(u => u.tenant === tenant.id) : allUsers, [allUsers, tenant])


    const { BaseFilter, baseFilteredData } = useBaseFilter({
        data: unfilteredData,
        isReadonly: u => !userOracle.canEdit(u),
        context: userType
    })

    const { filters, tagFilteredData } = useMultiTagFilter({
        filtered: baseFilteredData,
        tagged: unfilteredData,
        context: userType,
        tagsOf: u => u.tags
    })

    const data = tagFilteredData

    const initialSort: SortSpec[] = [{ key: 'name', mode: 'asc' }]

    return <Table
        name={userTableName}
        mountDelay context={userType} rowId={u => u.username}
        selection={selection}
        data={data} total={unfilteredData.length}
        initialSort={initialSort}>

        <Table.Filter>
            {BaseFilter}
        </Table.Filter>

        {Object.entries(filters).map(([category, filter]) =>

            <Table.Filter name={category} key={category}>
                {filter}
            </Table.Filter>
        )}

        {userCols}

    </Table>
}