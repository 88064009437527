import { Fragment, PropsWithChildren, useContext } from 'react'
import { ToggleContext } from './state'







export const useToggles = () => {

    const state = useContext(ToggleContext)

    return state.get().toggles

}


export const ToggleChangeObserver = (props: PropsWithChildren<{}>) => {

    const { children } = props

    const toggles = useToggles()

    return <Fragment key={JSON.stringify(toggles)}>
        {children}
    </Fragment>

}
