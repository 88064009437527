
import { useAppSettings } from '#settings/settings'
import { PatchAndCurrent } from '#submission/records'
import { VidLabel } from '#vid/label'
import { useVID } from '#vid/model'
import { useT } from 'apprise-frontend-core/intl/language'
import { TagLabel, TagLabels } from 'apprise-frontend-tags/tag/label'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { MultiTagColumn, TagColumn } from 'apprise-frontend-tags/tag/tagcolumn'
import { useTagUtils } from 'apprise-frontend-tags/tag/utils'
import { Label } from 'apprise-ui/label/label'
import { Column } from 'apprise-ui/table/table'
import { useTableUtils } from 'apprise-ui/table/utils'
import { ChangeLabel } from 'apprise-ui/utils/changelabel'
import { VesselGearIcon, VesselNameIcon, VesselPortIcon, VesselTypeIcon } from './constants'
import { DetailsPatch } from './model'
import { VesselIcon } from '#record/constants'



export const useDetailsColumns = () => {

    const t = useT()

    const tags = { ...useTagUtils(), ...useTagStore() }

    const vid = useVID()

    const { secondaryScheme } = useAppSettings()

    const { compareStringsOf } = useTableUtils<DetailsPatch>()

    return <>

        <Column<PatchAndCurrent<DetailsPatch>> defaultLayout width={250} name="vessel-name" title={<Label icon={<VesselNameIcon />} title={t('rec.vesselname_col')} />}

            text={patch => patch.details.name}
            sort={compareStringsOf(m => m.details.name)}
            render={m => <ChangeLabel before={m.current?.details.name} after={m.details.name} />} />

        ,

        <Column<PatchAndCurrent<DetailsPatch>> defaultLayout width={100} name="vessel-identifier"

            text={patch => vid.find(secondaryScheme, patch.details.identifiers)}
            title={<VidLabel scheme={secondaryScheme} bare />}
            render={m =>

                <ChangeLabel before={vid.find(secondaryScheme!, m.current?.details.identifiers)} after={vid.find(secondaryScheme!, m.details.identifiers)}

                    render={vid => <VidLabel noIcon vid={vid} />}

                />

            } />

        ,

        <TagColumn<PatchAndCurrent<DetailsPatch>> defaultLayout width={150} name="vessel-type" tagOf={patch => patch.details.vesselType} title={<Label bare icon={<VesselTypeIcon />} title={t('rec.vesseltype_col')} />}

            render={patch => <ChangeLabel before={patch.current?.details.vesselType} after={patch.details.vesselType} render={t => <TagLabel mode='tag' bare noIcon tag={t} />} />}
        />

        ,

        <MultiTagColumn<PatchAndCurrent<DetailsPatch>> defaultLayout width={200} name="vessel-gear" tagsOf={patch => patch.details.gears} title={<Label bare icon={<VesselGearIcon />} title={t('rec.vesselgear_col')} />}

            render={patch => <ChangeLabel before={patch.current?.details.gears} after={patch.details.gears} render={t => <TagLabels mode='tag' bare noIcon tags={t} />} />} />


        ,

        <Column<PatchAndCurrent<DetailsPatch>> width={200} defaultLayout name="vessel-port" title={<Label bare icon={<VesselPortIcon />} title={t('rec.vesselport_col')} />}

            text={patch => patch.details.port?.name}
            sort={(m1, m2) => tags.compareRef(m1.details.port?.name, m2.details.port?.name)}
            render={m => <ChangeLabel before={m.current?.details.port?.name} after={m.details.port?.name} />} />

    </>
}


export const usePreviousDetailsColumns = () => {

    const t = useT()

    const tags = { ...useTagUtils(), ...useTagStore() }

    return <>


        <Column<PatchAndCurrent<DetailsPatch>> width={200} name="vessel-prev-name" title={<Label bare icon={<VesselIcon />} title={t('rec.vesselprevname_col')} />}

            text={patch => patch.details.priorHistory.name}
            sort={(m1, m2) => tags.compareRef(m1.details.priorHistory.name, m2.details.priorHistory.name)}
            render={m => <ChangeLabel before={m.current?.details.priorHistory.name} after={m.details.priorHistory.name} />} />

        <Column<PatchAndCurrent<DetailsPatch>> width={200} name="vessel-prev-flags" title={<Label bare icon={<VesselIcon />} title={t('rec.vesselprevflag_col')} />}

            text={patch => patch.details.priorHistory.flags}
            sort={(m1, m2) => tags.compareRef(m1.details.priorHistory.flags, m2.details.priorHistory.flags)}
            render={m => <ChangeLabel before={m.current?.details.priorHistory.flags} after={m.details.priorHistory.flags} />} />

        <Column<PatchAndCurrent<DetailsPatch>> width={200} name="vessel-prev-deletion" title={<Label bare icon={<VesselIcon />} title={t('rec.vesselprevdeletion_col')} />}

            text={patch => patch.details.priorHistory.deletions}
            sort={(m1, m2) => tags.compareRef(m1.details.priorHistory.deletions, m2.details.priorHistory.deletions)}
            render={m => <ChangeLabel before={m.current?.details.priorHistory.deletions} after={m.details.priorHistory.deletions} />} />

    </>
}