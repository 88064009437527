
import { useMocks } from 'apprise-frontend-core/client/mocks'
import { useService } from 'apprise-frontend-core/client/service'
import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { useLifecycleMocks } from 'apprise-frontend-core/utils/lifecycle'
import { useTenancyRule, useTenantMocks } from 'apprise-frontend-iam/tenant/mockery'
import { TenantReference } from 'apprise-frontend-iam/tenant/model'
import { useUserMocks } from 'apprise-frontend-iam/user/mockery'
import { loremL, loremM } from 'apprise-ui/utils/constants'
import MockAdapter from 'axios-mock-adapter/types'
import { baseComboType } from '#basecombo/mode'
import addDays from 'date-fns/addDays'
import { detailsType } from '#details/constants'
import { useStreamMocks } from 'apprise-frontend-streams/mockery'
import { Bytestream } from 'apprise-frontend-streams/model'
import partition from 'lodash/partition'
import { useRavRecordMocks } from '#record/mockery'
import { RavRecord } from '#record/model'
import { submissionApi } from './calls'
import { submissionType } from './constants'
import { MatchReport, Submission, SubmissionDto, SubmissionLifecycle, submissionTypes, useSubmissionUtils } from './model'
import { RecordIssue, ValidationReport } from './validator'




export const useSubmissionMockery = () => {


    const client = useService()
    const submissionMocks = useSubmissionMocks()
    const byTenancy = useTenancyRule()
    const userMocks = useUserMocks()
    const records = useRavRecordMocks()

    return (mock: MockAdapter) => {

        console.log("mocking submissions...")

        const submissions = submissionMocks.store()

        const svc = client.get(submissionType)
        const api = `${svc.prefix}${submissionApi}`
        const regapi = RegExp(`${api}/.+$`)


        mock.onGet(api).reply(() => {

            const summaries =  submissions.all().filter(byTenancy).map(r => ({ ...r, records: undefined! }))

            return utils().waitNow(300).then(()=>[200, summaries])

        })

        mock.onGet(regapi).reply(({ url }) => {

            const id = url!.split('/').pop()

            return [200, submissions.oneWith(id)]
        })


        mock.onPost(api).reply(({ data }) => {

            const submission = JSON.parse(data) as Submission

            submission.lifecycle.created = Date.now()
            submission.lifecycle.lastModified = submission.lifecycle.created
            submission.lifecycle.lastModifiedBy = userMocks.logged().username

            return [200, submissions.add(submission)]
        })

        mock.onPut(regapi).reply(({ data, url }) => {

            const submission = JSON.parse(data) as Submission

            const id = url!.split('/').pop()

            if (submission.id !== id)
                throw Error('submission and route mismatch')

            const current = submissions.oneWith(id)

            if (!current)
                throw Error(`unknown submission ${id}`)

            const now = Date.now()

            submission.lifecycle.lastModified = now
            submission.lifecycle.lastModifiedBy = userMocks.logged().username

            // draft => submitted
            if (current.lifecycle.state === 'draft' && submission.lifecycle.state === 'submitted') {

                submission.lifecycle.submitted = now
                submission.lifecycle.submittedBy = userMocks.logged().username

            }


            // submitted => published
            if (current.lifecycle.state === 'submitted' && submission.lifecycle.state === 'published') {

                submission.lifecycle.published = now
                submission.lifecycle.publishedBy = userMocks.logged().username

                const submitted = submission.records.filter(r => r.lifecycle.state === 'published')

                const [updates, newrecords] = partition(submitted, r => r.uvi);
               
                // simulates publication
                console.log(`publishing ${submitted.length} records (${newrecords.length} for new vessels)...`)

                const updateMap = utils().index(updates).by(r => r.uvi)
                const storeMap = utils().index(records.store().all().filter(r => updateMap[r.uvi])).by(r => r.uvi)

                // merges patches over current records
                const updated = updates.map(r => ({ ...storeMap[r.uvi], ...r }))

                // assigns identifiers to new records.
                const assigned = newrecords.map(r => ({ ...r, uvi: records.mockVessel().uvi })) as RavRecord[]

                const assignedIds = utils().index(assigned).by(a => a.id)

                // timestamps each record and its patched slots.
                const published = [...updated, ...assigned].map(r => r.patchedSlots.reduce((acc, slot) =>

                    ({ ...acc, [slot]: { ...acc[slot], timestamp: now.toString() } }),

                    {
                        ...r,
                        timestamp: new Date(now).toISOString()
                    }))

                
                records.store().addMany(...published)

                // copies assugned id back into records, prior to returning it.
                submission.records.forEach(r => {

                    const assigned = assignedIds[r.id]

                    if (assigned)
                        r.uvi = assigned.uvi
                })

            }


            return [200, submissions.update(submission)]
        })

        mock.onDelete(regapi).reply(({ url }) => {

            const id = url!.split('/').pop()

            submissions.delete(id)

            return [204]
        })

        mock.onPost(`${api}/validate`).reply(({ data }) => {

            const sub = JSON.parse(data) as Submission

            return Promise.resolve([200, submissionMocks.mockValidationReport(sub)])
        })



        mock.onPost(`${api}/match`).reply(({ data }) => {

            const sub = JSON.parse(data) as Submission

            return Promise.resolve([200, submissionMocks.mockMatchReport(sub)])
        })
    }



}


export const useSubmissionMocks = () => {


    const t = useT()

    const mocks = useMocks()
    const { pastLifecycle } = useLifecycleMocks()
    const streams = useStreamMocks()
    const tenants = useTenantMocks()
    const records = useRavRecordMocks()

    const submissionUtils = useSubmissionUtils()

    const self = {

        store: () => mocks.getOrCreateStore<SubmissionDto>("submissions")

        ,


        stage: () => {

            const start = performance.now()

            const allTenants = utils().dedup(records.findRecords().current().now().map(r=>r.tenant)).map(tenants.tenantStore().get)

            const submissions = allTenants.map(t => t.id).flatMap((tenant, t) => {

                const currentRecords = records.findRecords().current().forTenant(tenant).now()

                return mocks.randomArray(5, 10).map((_, s) => self.mockSubmission(`SUB-${t}${s}`, tenant, JSON.parse(JSON.stringify(currentRecords))))

            })

            self.store().addMany(...submissions)

            console.log(`staged ${submissions.length} submissions in ${performance.now() - start}`)


        }


        ,

        mockSubmission: (

            id: string,
            tenant: TenantReference,
            currentRecords: RavRecord[]

        ) => {

            const state = mocks.randomBoolean(.7) ? mocks.randomBoolean(.7) ? 'published' : 'submitted' : 'draft'

            const lifecycle: SubmissionLifecycle = { ...pastLifecycle(state), created: mocks.randomDateSince(new Date(2021, 10, 1)).getTime() }

            if (state !== 'draft') {
                lifecycle.submitted = addDays(lifecycle.created!, mocks.randomNumberBetween(0, 7)).getTime()
                lifecycle.submittedBy = 'system'
            }

            if (state === 'published') {
                lifecycle.published = addDays(lifecycle.created!, mocks.randomNumberBetween(0, 7)).getTime()
                lifecycle.publishedBy = 'system'
            }

            const streamId = `bs-${id}`

            const type = mocks.randomBoolean(.5) ? baseComboType : mocks.randomIn(submissionTypes)

            const blob = new Blob([loremL], { type: 'text/plain' })

            const resource = {

                id,
                type: blob.type,
                name: `Some Bytestream for ${id}`,
                size: blob.size,
                lifecycle: pastLifecycle()

            } as Bytestream


            streams.blobStore().add({ id: streamId, data: blob })
            streams.streamStore().add(resource)

            const sub: SubmissionDto = {
                ...submissionUtils.newSubmission(),
                id,
                lifecycle,
                tenant,
                type,
                resources: [resource]
            }

            // records must be older than submission, and all about different uvis.
            const compatibleCurrentrecords = utils().indexAndDedup(currentRecords.filter(rec => new Date(rec.timestamp) < new Date(sub.lifecycle.created!))).by(t => t.uvi)

            const sameRecords: string[] = []

            sub.records = mocks.randomSlice(compatibleCurrentrecords, 8, compatibleCurrentrecords.length).map(current => {

                const recstate =

                    state === 'published' ?

                        // published submissions may contain published, rejected, or ignored records.
                        (mocks.randomBoolean(.7) ? 'published' : mocks.randomBoolean(.5) ? 'rejected' : 'ignored')

                        :


                        state === 'submitted' ?

                            // submitted submissions may contain submitted, rejected, or ignored records.
                            (mocks.randomBoolean(.7) ? 'submitted' : mocks.randomBoolean(.5) ? 'rejected' : 'ignored')

                            :

                            // draft submissions may contain uploaded or ignored records.
                            mocks.randomBoolean(.8) ? 'uploaded' : 'ignored'


                let { uvi, name } = sub.type !== baseComboType || mocks.randomBoolean(.7) ? { uvi: current.uvi, name: current.details.name } : { ...records.mockVessel(), uvi: undefined! }

                // occasionally changes name
                if (uvi !== undefined && [baseComboType, detailsType].includes(sub.type) && mocks.randomBoolean(.2))
                    name = records.mockVessel().name

                const generic = records.mockGeneric({
                    uvi,
                    state: recstate,
                    tenant: current.tenant,
                    patched: []
                })

                const noChange = state === 'draft' && mocks.randomBoolean(0.3);

                const patch = records.mockPatch({ noChange, current, base: generic, name, timestamp: new Date(lifecycle.created!).toISOString(), type })

                if (noChange)
                    sameRecords.push(patch.id)


                return patch



            })

            if (sub.type === baseComboType)
                sub.matchReport = self.mockMatchReport(sub)

            sub.validationReport = self.mockValidationReport(sub, sub.matchReport)

            sameRecords.forEach(id => sub.validationReport?.records[id].unshift({ message: t('valid.unchanged_patch'), type: 'warning' }))


            // watch out: stats rely on records and validation report, so should be computed last.
            sub.stats = submissionUtils.computeRecordStats(sub)

            return sub

        }

        ,

        mockValidationReport: (submission: Submission, matchReport?: MatchReport) =>

            (submission.records ?? []).reduce((acc, r) => {

                const recstate = r.lifecycle.state

                const status = recstate === 'ignored' ? 'invalid' as const :
                    recstate === 'published' || recstate === 'submitted' || recstate === 'rejected' ? 'valid' as const :
                        mocks.randomBoolean(.9) ? 'valid' as const : 'invalid' as const

                const issues = (status === 'invalid' ?

                    Array.from({ length: mocks.randomNumberBetween(3, 5) }).map(() => ({ message: loremM, type: mocks.randomBoolean(.7) ? 'error' : 'warning' }))
                    :
                    mocks.randomBoolean(.5) ? [{ message: loremM, type: 'warning' }] : undefined!

                ) as RecordIssue[]

                if (issues && matchReport?.[r.id]) {

                    const entry = matchReport?.[r.id]

                    if (entry.matches && !entry.matched && !entry.unmatched)
                        issues.unshift({ message: t('valid.unmatched_uvi'), type: 'error', location: 'uvi' })

                }



                return { ...acc, records: { ...acc.records, [r.id]: issues ?? [] } }


            }, { resources: {}, records: {} } as ValidationReport)

        ,

        mockMatchReport: (submission: Submission) => {

            const submissionUvis = submission.records.map(r => r.uvi)

            return (submission.records ?? []).filter(r => !r.uvi).reduce((acc, r) => {

                if (mocks.randomBoolean(.5))
                    return acc

                const [sameTenant, otherTenants] = partition(records.findRecords().current().now(), r => r.tenant === submission.tenant)

                // same-tenant, force not-duplicates
                const validMatches = mocks.randomSlice(sameTenant.filter(r => !submissionUvis.includes(r.uvi)), 3, 5)

                // // one foreign (if found), one duplicate.
                const invalidMatches = [

                    mocks.randomIn(otherTenants),
                    mocks.randomIn(sameTenant.filter(r => !validMatches.includes(r)))
                ]

                const matches = [...validMatches, ...invalidMatches.filter(m => !!m)].sort(() => mocks.randomBoolean() ? 1 : -1)

                const matched = submission.lifecycle.state === 'draft' ? undefined : mocks.randomIn(matches).uvi

                if (matched)
                    r.uvi = matched

                const unmatched = submission.lifecycle.state === 'draft' || matched ? undefined : true as const

                return { ...acc, [r.id]: { matches, matched, unmatched } } as MatchReport


            }, {} as MatchReport)
        }


    }

    return self
}
