
import * as React from 'react'
import { Authz } from './authz/authz'
import { ToolbridgeProps, ToolbridgeProvider } from './utils/toolbridge'


export type ApplicationProps = ToolbridgeProps

// syntactic sugar: mounts the state of interdependent-modules on behalf of an application.
// clients can continue to configure the modules as usual, even cherrypick them as mounting their state is no-op.
export const Application = (props: ApplicationProps) => {

    const { children, tool } = props

    return <ToolbridgeProvider tool={tool}>
        <Authz>
            {children}
        </Authz>
    </ToolbridgeProvider>
}