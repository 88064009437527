import { fallbackStateOver, State } from 'apprise-frontend-core/state/api';
import { utils } from 'apprise-frontend-core/utils/common';
import { createContext, useContext } from 'react';
import { Config, defaultBaseConfig } from './model';






// stores configuration provided by the client and configuration fetched from the backend.
// client configuration is used to provide defaults, or if the remote configuratiion it isn't used at all.
export type ConfigState = {

    // provided by client.
    clientConfig: Config

    // fetched from backend.
    remoteConfig: Config
}

export const initialConfigState: ConfigState = {

    clientConfig: defaultBaseConfig,
    remoteConfig: undefined!
}


// state-holding context, serves default until config mgmt is active, if ever.
export const ConfigContext = createContext<State<ConfigState>>(fallbackStateOver(initialConfigState))


export type ToggleState = {

    toggles: string[]
}

export const initialToggleState: ToggleState = {

    toggles: []
}

// state-holding context, serves default until config mgmt is active, if ever.
export const ToggleContext = createContext<State<ToggleState>>(fallbackStateOver(initialToggleState))


// this is an internal api.
export const useConfigState = () => {

    const state = useContext(ConfigContext)
    const toggleState = useContext(ToggleContext)

    const self = {

        clientConfig: () => state.get().clientConfig

        ,

        // fetched from server.
        remoteConfig: () => state.get().remoteConfig

        ,

        // merges into existing client config.
        addClientConfig: (config: Config) => state.set(s => s.clientConfig = utils().merge(self.clientConfig(), config))

        ,

        // merges into existing client config.
        addRemoteConfig: (config: Config) => {
        
            const merged = utils().merge(self.clientConfig() ?? {}, config)
            state.set(s => s.remoteConfig = merged)
            toggleState.set( s => s.toggles = merged.toggles ?? s.toggles )
        }

        ,

        addToggles:  <S extends string = string> (...toggles:S[]) => toggleState.set(s => s.toggles = utils().dedup([...s.toggles ?? [], ...toggles]))

        ,

        removeToggles: <S extends string = string> (...toggles:S[]) => toggleState.set(s => s.toggles =s.toggles.filter(t=>!toggles.includes(t as S)))

    }

    return self



}

