import { baseComboType } from '#basecombo/mode'
import { delistingType } from '#delisting/constants'
import { Delisting } from '#delisting/model'
import { detailsType } from '#details/constants'
import { RavRecord } from '#record/model'
import { useSearch } from '#search/api'
import { Asset, Submission, SubmissionType, useSubmissionModel } from '#submission/model'
import { useSubmissionRouting } from '#submission/routing'
import { useVID } from '#vid/model'
import { utils } from 'apprise-frontend-core/utils/common'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { noTenant } from 'apprise-frontend-iam/tenant/model'
import { useMail } from 'apprise-frontend-mail/api'
import { noMailTopic } from 'apprise-frontend-mail/model'
import { isBytestream } from 'apprise-frontend-streams/model'
import { useBytestreamTracker } from 'apprise-frontend-streams/track/tracker'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'
import { useContext } from 'react'
import { useVesselCalls } from './calls'
import { DetailContext } from './context'
import { Vessel } from './model'
import { useVesselRouting } from './routing'

export const useEffects = () => {

    const task = useAsyncTask()

    const search = useSearch()

    const vid = useVID()

    const mail = useMail()

    const calls = useVesselCalls()
    const routing = useVesselRouting()
    const tracker = useBytestreamTracker()

    const logged = useTenancyOracle()

    const submissions = { ...useSubmissionModel(), ...useSubmissionRouting() }

    const { vessel, form } = useContext(DetailContext)

    const { edited, reset, set } = form

    // saves changes into a draft submission of a given type from the flagstate.
    const save = async (record: RavRecord = edited, type: SubmissionType = baseComboType) => {

        // upload photos, if any.
        const uploaded = await tracker.upload(...record.photograph?.photos.map(p => p.ref as Asset).filter(ref => typeof ref === 'object') ?? [])

        const uploadedMap = utils().index(uploaded).by(s => s.id)

        tracker.reset()

        let editabledRecord = { ...record }

        const submission: Submission = {

            ...submissions.newSubmission(),

            type,
            tenant: editabledRecord.tenant,

            live: true,

            lifecycle: { state: 'draft' }
        }

        // binds photos to submissions (so they get reclaimed is submission is then discarded)

        if (uploaded.length)
            editabledRecord.photograph = { ...editabledRecord.photograph, photos: editabledRecord.photograph?.photos.filter(p => isBytestream(p.ref)).map(photo => ({ ...photo, ref: { ...uploadedMap[(photo.ref as Asset).id] || photo.ref, ref: submission.id } })) }

        submission.records = [editabledRecord]

        reset.toInitial.quietly()

        //console.log("creating", { type: submission.type, record: submission.records[0] })

        submissions.routeToNewDetail({ nextSubmission: submission })



    }

    const delist = (delisting: Delisting) => {

        const delisted: RavRecord = {

            ...edited,

            patchedSlots: [delistingType],

            [delistingType]: delisting
        }

        save(delisted, delistingType)

    }

    const remove = task.make(async (record: RavRecord) => {

        await calls.remove(record)

        search.runQueryQuietly()

        if (vessel.history.length === 1)
            routing.routeToSearch()

    })
        .with($ => $.notify())
        .withConsent().done()


    const claim = () => {

        if (logged.isSingleTenantUser())

            set.using(s => {

                if (logged.tenant() === s.tenant) { // reclaim (same tenant)
                    // little hack: changes timestamp to force-drity the form, as the tenant doesnt' change.
                    s.timestamp = new Date().toISOString()
                }
                else { // claim (different tenant)
                    s.tenant = logged.tenant()
                    s.details.flagstate = s.tenant
                }
            })

        else
            routing.routeToInnerDetailForm(detailsType)

    }

    const merge = async (target: Vessel, mergedTargetHistory: RavRecord[]) => {

        await utils().waitNow(1000)   //todo

        const firstSourceRevision = vessel.history[0]
        const firstTargetRevision = target.history[0]

        // temporarily disabled.
        //calls.applyMerge({source: vessel.uvi, target:firstRevision.uvi, mergedTargetHistory })    

        mail.sendTo([noTenant, firstTargetRevision.tenant])
            .template('mergevessel')
            .params({
                
                vesselUvi: vid.valueOf(vessel.uvi)!,
                source_name: firstSourceRevision.details.name,
                source_uvi: vid.valueOf(vessel.uvi)!,
                target_name: firstTargetRevision.details.name,
                target_uvi: vid.valueOf(firstTargetRevision.uvi)!

            }).on(noMailTopic);


    }


    return { save, remove, delist, claim, merge }
}