import { useToggles } from "apprise-frontend-core/config/toggles"

export const allUserTogggles = ['user-download'] as const

export type UserToggles = typeof allUserTogggles[number]

export const useUserToggles = () => {
    const toggles = useToggles() as UserToggles[]

    const self = {
        get download() {
            return toggles.includes('user-download')
        }
    }

    return self
}